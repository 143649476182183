import Router from 'next/router';

import slugify from 'slugify';
import { SITE_BASE_URL } from '../../constants';

export interface QueryObject {
  [key: string]: any,
}

export interface URLParamsObject {
  url?: string,
  as?: string,
}

export interface URLObject {
  url?: {
    pathname: string,
    query: QueryObject,
  } | string,
  as?: string,
}

export function buildURLObject(
  pathname: string,
  as: string,
  query: QueryObject,
): URLObject {
  return {
    url: {
      pathname,
      query,
    },
    as,
  };
}

export function withQueryString(
  as: string,
  query: QueryObject = {},
) {
  const serialized = Object.entries(query).reduce((result, [key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      result.append(key, value);
    }

    return result;
  }, new URLSearchParams()).toString();

  return `${as}${serialized.length ? `?${serialized}` : ''}`;
}

export function withCanonicalPageQueryString(
  as: string,
  query: QueryObject = {},
) {
  return withQueryString(as, {
    ...query,
    p: query.p === 1 ? undefined : query.p,
  });
}

export function slugIt(value: string) {
  return slugify(value, { remove: /[^\w\s-]/g, replacement: '-', lower: true });
}

export function goToPage(
  { url, as }: URLObject,
  replace: boolean = false,
) {
  const fn = replace ? 'replace' : 'push';
  Router[fn](url, as || url);
}

export function relativeToAbsoluteURL(relativePath: string, query?: QueryObject): string {
  if (!/^\//gi.test(relativePath)) {
    throw new Error('Error: a valid relative path starts with a "/" character.');
  }

  const absoluteURL = `${SITE_BASE_URL}${relativePath}`.replace(/\/$/, '');

  return query
    ? withQueryString(absoluteURL, query)
    : absoluteURL;
}
