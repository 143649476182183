export interface Node {
  __typename: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  publishedAt?: string,
}

export interface Agency extends Node {
  id: string,
  info?: string,
  office?: string,
  evaluation?: string,
  agents: Agent[],
  imageBlock?: ImageBlock,
  heroImage?: ImageBlock,
  address?: Address,
  locality?: Locality,
  realEstateAgencies: RealEstateAgency[],
  seo?: Seo,
  claim?: string,
}

export interface Address extends Node {
  id: string,
  agency?: Agency,
  locality: string,
  streetAddress: string,
  postalCode: string,
  region?: string,
}

export interface AdvFluid extends Node {
  advFluidType: AdvFluidType,
  variant: AdvFluidVariant,
}

export interface JobTitle extends Node {
  name: string,
  rank: number,
}

export interface Agent extends Node {
  id: string,
  reviews: Review[],
  agencies: Agency[],
  name: string,
  surname: string,
  jobTitleType: JobTitle,
  photo: Asset,
  email: string,
  realEstateAgencies?: RealEstateAgency[],
  biography?: string,
  interview?: string,
  area?: string,
  slug: string,
}

export interface AgentStory extends Node {
  id: string,
  bio: string,
  quote: string,
  agent: Agent,
}

export interface Area extends Node {
  id: string,
  serpContent?: SerpContent,
  name: string,
  slug: string,
  locality?: Locality,
  key: string,
}

export interface ArticleBlock extends Node {
  id: string,
  new?: New,
  article?: Guide,
  title: string,
  alternativeTitle?: string,
  content: string,
  evaluation?: Evaluation,
  sectionsBuildingSite?: BuildingSite,
  locationBuildingSite?: BuildingSite,
  propertiesSectionBuildingSite?: BuildingSite,
  headingTitleImportance?: HeadingTitleImportance,
}

export interface Asset extends Node {
  locale: Locale,
  localizations: Asset[],
  id: string,
  createdAt: string,
  updatedAt: string,
  publishedAt?: string,
  handle: string,
  fileName: string,
  height: number,
  width: number,
  size?: number,
  mimeType?: string,
  imageImageBlock: ImageBlock[],
  photoAgent: Agent[],
  url: string,
}

export interface BuildingSite extends Node {
  id: string,
  title: string,
  suburb?: string,
  abstract?: string,
  thumbnail?: ImageBlock,
  minPrice: number,
  minDimension: number,
  minRooms: number,
  maxRooms: number,
  route?: string,
  normalizedAddress?: string,
  energyClass?: EnergyClass,
  energyValue?: string,
  siteStatus?: string,
  logo?: ImageBlock,
  images: ImageBlock[],
  virtualTour?: string,
  sections: ArticleBlock[],
  location?: ArticleBlock,
  propertiesSection?: ArticleBlock,
  propertyIds: number[],
  intentPropertyId?: number,
  agent?: Agent,
  locality?: Locality,
}

export enum Department {
  OPERATIONS = 'OPERATIONS',
  SALES = 'SALES',
  HR = 'HR',
  TECH = 'TECH',
  MARKETING = 'MARKETING',
  PRODUCT = 'PRODUCT',
  CONTENT = 'CONTENT',
  CONTACT = 'CONTACT',
  FINANCE = 'FINANCE',
}

export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export enum EnergyClass {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export interface Evaluation extends Node {
  id: string,
  articleBlocks: ArticleBlock[],
  locality?: Locality,
}

export interface Article extends Node {
  id: string,
  title: string,
  alternateTitle?: string,
  abstract: string,
  metaPreview?: ImageBlock,
  imageBlock?: ImageBlock,
  introduction: string,
  seo?: Seo,
  published: string,
  mainColumn: Array<ArticleBlock | AdvFluid | SavingCalculator | EvaluationLeadWizard | ImageBlock>,
  rightColumn?: AdvFluid,
}

export interface New extends Article {
  category: NewsCategory,
  localities: Locality[],
}

export interface Guide extends Article {
  categories: string[],
  target: Target,
  summaryTitle?: string,
}

export interface ImageBlock extends Node {
  id: string,
  links: Link[],
  new?: New,
  guides: Guide[],
  image?: Asset,
  imageAlt?: string,
  agencies: Agency[],
  serpArticle?: SerpContentBlock,
  buildingSite?: BuildingSite,
  logoBuildingSite?: BuildingSite,
  imagesBuildingSite?: BuildingSite,
}

export interface JobOffer extends Node {
  id: string,
  employmentType: EmploymentType,
  abstract: string,
  description: string,
  jobPosition: JobPosition,
  province?: Province,
}

export interface JobPosition extends Node {
  id: string,
  name: string,
  department: Department,
}

export interface Link extends Node {
  id: string,
  title: string,
  path: string,
  imageBlock?: ImageBlock,
  promotionalLinks: PromotionalLink[],
}

export interface Locality extends Node {
  id: string,
  news: New[],
  agency?: Agency,
  serpContents: SerpContent[],
  areas: Area[],
  name: string,
  evaluation?: Evaluation,
  slug: string,
  buildingSites: BuildingSite[],
  capitalOf?: Province,
  province?: Province,
}

export interface Location {
  latitude: number,
  longitude: number,
  distance: number,
}

export enum NewsCategory {
  CURIOSITA = 'Curiosita',
  IMMOBILIARE = 'Immobiliare',
  MERCATO = 'Mercato',
  MUTUO = 'Mutuo',
  TASSAZIONE = 'Tassazione',
  INTERVISTA_AGENTE = 'IntervistaAgente',
}

export interface PageInfo {
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  startCursor?: string,
  endCursor?: string,
  pageSize?: number,
}

export interface PromotionalLink extends Node {
  id: string,
  links: Link[],
  category: PromotionalLinksCategory,
}

export enum PromotionalLinksCategory {
  EDUCATION = 'Education',
  GUIDES = 'Guides',
  NEWS = 'News',
}

export interface Province extends Node {
  id: string,
  reviews: Review[],
  serpContent?: SerpContent,
  name: string,
  value?: string,
  slug: string,
  plateCode: string,
  capital?: Locality,
  localities: Locality[],
}

export interface RealEstateAgency extends Node {
  id: string,
  name: string,
  slug: string,
  email: string,
  franchising: boolean,
  phoneNumber?: string,
  info?: string,
  team?: string,
  evaluation?: string,
  imageBlock?: ImageBlock,
  address?: Address,
  parent?: Agency,
  agents: Agent[],
}

export interface Review extends Node {
  id: string,
  author: string,
  date: string,
  rating: number,
  title?: string,
  review: string,
  province?: Province,
  agents: Agent[],
  url?: string,
}

export interface Seo extends Node {
  id: string,
  new?: New,
  guide?: Guide,
  guideSERP?: GuideSERP,
  title: string,
  description: string,
  keywords: string[],
}

export interface SerpContent extends Node {
  id: string,
  hasAgency: boolean,
  hasEvaluation: boolean,
  blocks: SerpContentBlock[],
  province?: Province,
  locality?: Locality,
  area?: Area,
}

export interface SerpContentBlock extends Node {
  id: string,
  serpContent?: SerpContent,
  title: string,
  content: string,
  imageBlock?: ImageBlock,
}

export interface GuideSERP extends Node {
  category: string,
  title?: string,
  seo?: Seo,
}

export enum Category {
  AGENTI = 'Agenti',
}

export enum Target {
  AGENTE = 'Agente',
  COMPRATORE = 'Compratore',
  COSTRUTTORE = 'Costruttore',
  VENDITORE = 'Venditore',
}

export enum ReviewTarget {
  COMPRATORE = 'Compratore',
  VENDITORE = 'Venditore',
}

export enum AdvFluidType {
  SELL_PROPERTY = 'SELL_PROPERTY',
  AGENT_JOB_APPLICATION = 'AGENT_JOB_APPLICATION',
  FRANCHISING = 'FRANCHISING',
  BUY_PROPERTY = 'BUY_PROPERTY',
  EVALUATION = 'EVALUATION',
  BUYER_CONSULTANCY = 'BUYER_CONSULTANCY',
}

export enum AdvFluidVariant {
  VARIANT_1 = 'VARIANT_1',
  VARIANT_2 = 'VARIANT_2',
}

export enum NodeType {
  ADV_FLUID = 'AdvFluid',
  ARTICLE_BLOCK = 'ArticleBlock',
}

export enum SavingCalculatorType {
  SAVE = 'SAVE',
  PAY = 'PAY',
}

export interface SavingCalculator extends Node {
  savingCalculatorType: SavingCalculatorType,
  title: string,
  description: string,
  priceLabel?: string,
  claim?: string,
  cta: string,
}

export interface EvaluationLeadWizard extends Node {
  title: string,
  description: string,
}

export interface QuizAnswer extends Node {
  answer: string,
  correct: boolean,
}

export enum QuizQuestionCategory {
  NORMATIVA = 'NORMATIVA',
  TECNICA = 'TECNICA',
}

export interface QuizEntry extends Node {
  question: string,
  category: QuizQuestionCategory,
  answers: QuizAnswer[],
}

export interface PressArticle extends Node {
  title: string,
  abstract: string,
  logo?: Asset,
  newspaper: string,
  publishedDate: string,
  link: string,
}

export interface PressRelease extends Node {
  title: string,
  publishedDate: string,
  release: Asset,
}

export enum HeadingTitleImportance {
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
